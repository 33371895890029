export default {
  subscribe() {},
  skipCachedEvents() {},
  setDebug() {},
  setIdentity() {},
  userCompletedAction() {},
  sendCommerceEvent() {},
  openURL() {},
  redeemRewards() {},
  loadRewards() {},
  get BranchEvent() {
    return {};
  },
  AddToCartEvent: '',
  AddToWishlistEvent: '',
  PurchasedEvent: '',
  PurchaseInitiatedEvent: '',
  RegisterViewEvent: '',
  ShareCompletedEvent: '',
  ShareInitiatedEvent: '',
  VERSION: '',
};
