import * as React from 'react';
import { Text, View } from 'react-native';

export default class AdMobBanner extends React.Component<{}> {
  render() {
    return (
      <View>
        <Text>AdMobBanner component not supported on the web</Text>
      </View>
    );
  }
}
